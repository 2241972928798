import React, { useState, useCallback } from 'react'

import { graphql } from 'gatsby'
import debounce from 'lodash.debounce'
import { GatsbyImage } from 'gatsby-plugin-image'
// import { isMobile } from 'react-device-detect'
// import { motion } from 'framer-motion'

import Layout from '@components/common/layout'
import FullScreenHeader from '@components/scrollytelling/annual-report/full-screen-header'
import FullHeightSlide from '@components/scrollytelling/reports/full-height-slide'
import TopNav from '@components/scrollytelling/reports/top-navigation'

// Import data
import CHAPTERS from '@data/annual-report-2022/CHAPTERS'

const AnnualReport2022Story = (props) => {
	const [currentChapter, setCurrentChapter] = useState('welcome')

	const Images = props.data.allFile.edges

	const changeChapter = (newChapter) => {
		setCurrentChapter(newChapter)
	}

	const debouncedchangeChapter = useCallback(debounce(changeChapter, 50), [])

	const getImageByName = useCallback(
		(name) => {
			// Wrap function in try catch block to prevent build error
			try {
				return Images.filter((el) => el.node.name === name)[0].node.childImageSharp.gatsbyImageData
			} catch (e) {
				console.log(e, name)
				return null
			}
		},
		[Images]
	)

	return (
		<Layout
			bgColor={'bg-white'}
			shareProps={{
				title: 'The New Humanitarian | Annual Report 2022', // TODO
				url: 'https://interactive.thenewhumanitarian.org/reports/2022/05/31/annual-report-2021', // TODO
				socialTitle: 'The New Humanitarian | Annual Report 2022', // TODO
				socialDescription: 'Our journalism, impact, audience, and more.', // TODO
				socialImage: 'https://www.thenewhumanitarian.org/s3fs_to_s3/files/styles/responsive_large/public/2023-06/teaser.png.webp?itok=fx7Y1Dgz',
				nodeId: null,
				updatedTime: '2023-06-27T08:00:00+00:00',
				modiefiedTime: '2023-06-27T08:00:00+00:00',
				publishedTime: '2023-06-27T08:00:00+00:00',
			}}
		>
			<FullScreenHeader
				title={['Journalism from the heart of crises']}
				subtitle={'The New Humanitarian Annual Report 2022'}
				image={getImageByName('header')}
				downloads={[
					{
						text: 'Annual Report 2022',
						url: 'https://assets.thenewhumanitarian.org/s3fs-public/2023-06/TNH%20Annual%20Report%202022.pdf',
					},
					{
						text: 'Executive Summary',
						url: 'https://assets.thenewhumanitarian.org/s3fs-public/2023-06/TNH%20Annual%20Report%202022%20Executive%20Summary.pdf',
					},
				]}
			></FullScreenHeader>

			<div id='start' />

			<TopNav chapters={CHAPTERS} currentChapter={currentChapter} />
			{CHAPTERS.map((el) => {
				return (
					<FullHeightSlide
						chapters={CHAPTERS}
						className={`overflow-x-hidden ${el.className}`}
						chapter={el.id}
						inViewHandler={debouncedchangeChapter}
						key={`chapter-${el.id}`}
					>
						{el.backgroundImage && (
							<GatsbyImage
								alt={''}
								image={getImageByName(el.backgroundImage)}
								style={{
									height: '100%',
									width: '100%',
									position: 'absolute',
									top: 0,
									left: 0,
								}}
								imgStyle={{ objectFit: 'cover' }}
								className={'z-0'}
							/>
						)}
						{el.htmlContent ? <div className={'z-0'}>{el.htmlContent}</div> : <h2 className={'text-burgundy bg-white px-5 py-3'}>{el.title}</h2>}
					</FullHeightSlide>
				)
			})}
		</Layout>
	)
}

export default AnnualReport2022Story

export const query = graphql`
	query AnnualReport2022Images {
		allFile(
			filter: { sourceInstanceName: { eq: "images" }, extension: { in: ["jpg", "png", "jpeg"] }, relativePath: { glob: "reports/annual-report-2022/*" } }
		) {
			edges {
				node {
					extension
					dir
					modifiedTime
					name
					relativePath
					childImageSharp {
						gatsbyImageData(
							placeholder: BLURRED
							width: 1920
							quality: 85
							layout: FULL_WIDTH
						)
					}
				}
			}
		}
	}
`
