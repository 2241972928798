import React from 'react'

import Typist from '@components/common/typist'
import * as Icons from '@components/scrollytelling/reports/infographic-icons'
import Link from '@components/common/link'

const ChapterHeader = ({ title, textShadow, quote }) => {
	return (
		<div className={'px-5 flow flex flex-col justify-center items-center'}>
			<h1
				style={{ textShadow: textShadow ? '2px 2px 15px rgba(0,0,0,0.6)' : 'none' }}
				className={'text-white text-center md:text-7xl lg:text-8xl xl:text-9xl'}
			>
				{title}
			</h1>
			<h2 className={'z-50 px-3 py-2 inline-block mx-auto text-white bg-burgundy text-center text-xl sm:text-5xl'} style={{ marginTop: '2.8rem' }}>
				<Typist texts={quote} />
			</h2>
		</div>
	)
}

const InlineYoutubeVideo = ({ caption, src }) => {
	return (
		<div className={'relative h-0 pt-sixteenbynine mx-auto py-12 w-full mb-20'}>
			<iframe
				className={'absolute top-0 left-0 w-full h-full'}
				width='100%'
				height='auto'
				src={src}
				title='YouTube video player'
				frameborder='0'
				allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
				allowFullScreen
			/>
			{caption && (
				<p style={{ background: '#f2f2f2', color: '#63666a' }} className={'text-gray-600 p-4 mt-12'}>
					{caption}
				</p>
			)}
		</div>
	)
}

const InlineVideo = ({ caption, fileName, autoPlay, loop }) => {
	return (
		<div className={'sm:mx-0 md:-mx-10 lg:-mx-72 xl:-mx-96 py-12'}>
			<video autoPlay={autoPlay || true} muted={autoPlay || muted || false} loop={loop || true} playsInline>
				<source src={`/images/reports/annual-report-2021/${fileName}`} />
			</video>
			{caption && (
				<p style={{ background: '#f2f2f2', color: '#63666a' }} className={'text-gray-600 p-4'}>
					{caption}
				</p>
			)}
		</div>
	)
}

const InlineImage = ({ caption, fileName, wide }) => {
	return (
		<div className={`py-12 ${wide ? 'sm:mx-0 md:-mx-52 lg:-mx-84 xl:-mx-96' : 'sm:mx-0 md:-mx-10 lg:-mx-56 xl:-mx-80'}`}>
			<img src={`/images/reports/annual-report-2022/${fileName}`} />
			{caption && (
				<p style={{ background: '#f2f2f2', color: '#63666a' }} className={'text-gray-600 p-4'}>
					{caption}
				</p>
			)}
		</div>
	)
}

const InlineAudio = ({ externalLink, externalLinkText, fileName, type }) => {
	return (
		<div className={'py-12 w-full mx-auto text-center'}>
			<audio controls className={'w-full mb-8'}>
				<source src={require('../../audio/reports/annual-report-2021/' + fileName).default} type={type || 'audio/mp3'} />
				Your browser does not support the audio element.
			</audio>
			<div className={'mx-auto'}>
				<Link href={require('../../audio/reports/annual-report-2021/' + fileName).default}>Download audio file</Link>
				{externalLinkText && externalLink && (
					<>
						<span className={'px-3'}>or</span>
						<Link href={externalLink}>
							<span>{externalLinkText}</span>
						</Link>
					</>
				)}
			</div>
		</div>
	)
}

const BlockImage = ({ fileName }) => {
	return (
		<div>
			<img src={`/images/reports/annual-report-2022/${fileName}`} />
		</div>
	)
}

const InlineTypist = ({ text, textAlign }) => {
	return (
		<h1 className={`z-50 w-full inline-block mx-auto text-burgundy text-center text-2xl sm:text-5xl mb-8 ${textAlign || 'text-center'}`}>
			{/* {text.length > 1 ? text[0] : text} */}
			<Typist texts={text} />
		</h1>
	)
}

const InlineQuote = ({ person, text }) => {
	return (
		<>
			<blockquote style={{ marginTop: '3rem', marginBottom: '3rem' }} className={'text-2xl sm:text-4xl sm:mx-0 md:-mx-10 lg:-mx-56 xl:-mx-80'}>
				{text} <span className={'block pt-4 text-gray-500 text-3xl'}>– {person}</span>
			</blockquote>
		</>
	)
}

const MarginBreakContainer = ({ children }) => {
	return <div className={'py-16 margin-break'}>{children}</div>
}

const CHAPTERS = [
	{
		title: 'Welcome',
		id: 'welcome',
		backgroundImage: 'welcome',
		className: 'bg-gray-300',
		htmlContent: <ChapterHeader title={'Welcome'} subtitle={'A year of new beginnings'} quote={['Here’s what we achieved.']} />,
		fullContent: (
			<div className={'px-5 flow flex flex-col justify-center items-start max-w-6xl mx-auto sm:pr-28'}>
				<InlineTypist text={['Here’s what we achieved.']} />
				<p>
					In line with a newly launched strategy, 2022 was a year of new beginnings for The New Humanitarian. We spent the year building new
					approaches, embedding a decolonisation mindset into our day-to-day journalism; experimenting with new formats in our keystone projects; and
					imbuing our work with a focus on solutions and maximum impact.
				</p>
				<p>
					Other new beginnings included producing bespoke products for social media and platforms other than our website; the acquisition of a premium
					newsletter, marking our first foray into earned revenue; and the launch of an individual giving programme to attract new types of donors to
					our community of supporters. We also invested in our staff’s well-being, with a revised compensation and benefits framework and a more
					equitable approach to hiring people around the world.
				</p>
				{/* <div className={'mx-auto my-5 pt-5'}>
					<TNHLogo />
				</div> */}
				<h2>2022 at a glance</h2>
				<MarginBreakContainer>
					<div className={'gap-10 grid grid-cols-1 sm:grid-cols-3 justify-center items-start sm:mx-0 md:-mx-10 lg:-mx-56 xl:-mx-80'}>
						<div className={'flex items-center justify-center flex-col text-center'}>
							<div className={'text-burgundy w-32'}>
								<Icons.Clipboard />
							</div>
							<h3 className={'text-3xl sm:text-5xl'} style={{ marginTop: '2rem' }}>
								344 reports
							</h3>
						</div>
						<div className={'flex items-center justify-center flex-col text-center'}>
							<div className={'text-burgundy w-32'}>
								<Icons.Star />
							</div>
							<h3 className={'text-3xl sm:text-5xl'} style={{ marginTop: '2rem' }}>
								4 investigations
							</h3>
						</div>
						<div className={'flex items-center justify-center flex-col text-center'}>
							<div className={'text-burgundy w-32'}>
								<Icons.Trophy />
							</div>
							<h3 className={'text-3xl sm:text-5xl'} style={{ marginTop: '2rem' }}>
								3 awards and nominations
							</h3>
						</div>
						<div className={'flex items-center justify-center flex-col text-center'}>
							<div className={'text-burgundy w-32'}>
								<Icons.Newspaper />
							</div>
							<h3 className={'text-3xl sm:text-5xl'} style={{ marginTop: '2rem' }}>
								210,134 monthly readers on average
							</h3>
						</div>
						<div className={'flex items-center justify-center flex-col text-center'}>
							<div className={'text-burgundy w-32'}>
								<Icons.SocialMedia />
							</div>
							<h3 className={'text-3xl sm:text-5xl'} style={{ marginTop: '2rem' }}>
								227,301 followers on social media
							</h3>
						</div>
						<div className={'flex items-center justify-center flex-col text-center'}>
							<div className={'text-burgundy w-32'}>
								<Icons.Youtube />
							</div>
							<h3 className={'text-3xl sm:text-5xl'} style={{ marginTop: '2rem' }}>
								614,800 views on YouTube
							</h3>
						</div>
						<div className={'flex items-center justify-center flex-col text-center'}>
							<div className={'text-burgundy w-32'}>
								<Icons.Podcast />
							</div>
							<h3 className={'text-3xl sm:text-5xl'} style={{ marginTop: '2rem' }}>
								145,883 podcast downloads
							</h3>
						</div>
						<div className={'flex items-center justify-center flex-col text-center'}>
							<div className={'text-burgundy w-32'}>
								<Icons.Group />
							</div>
							<h3 className={'text-3xl sm:text-5xl'} style={{ marginTop: '2rem' }}>
								500 paying members
							</h3>
						</div>
						<div className={'flex items-center justify-center flex-col text-center'}>
							<div className={'text-burgundy w-32'}>
								<Icons.Globe />
							</div>
							<h3 className={'text-3xl sm:text-5xl'} style={{ marginTop: '2rem' }}>
								135 territories covered
							</h3>
						</div>
						<div className={'flex items-center justify-center flex-col text-center'}>
							<div className={'text-burgundy w-32'}>
								<Icons.Handshake />
							</div>
							<h3 className={'text-3xl sm:text-5xl'} style={{ marginTop: '2rem' }}>
								22 donors
							</h3>
						</div>
						<div className={'flex items-center justify-center flex-col text-center'}>
							<div className={'text-burgundy w-32'}>
								<Icons.Staff />
							</div>
							<h3 className={'text-3xl sm:text-5xl'} style={{ marginTop: '2rem' }}>
								5 new hires
							</h3>
						</div>
						<div className={'flex items-center justify-center flex-col text-center'}>
							<div className={'text-burgundy w-32'}>
								<Icons.Microphone />
							</div>
							<h3 className={'text-3xl sm:text-5xl'} style={{ marginTop: '2rem' }}>
								18 speaking engagements
							</h3>
						</div>
					</div>
				</MarginBreakContainer>
				<p>
					Our <Link href='https://interactive.thenewhumanitarian.org/reports/2022/12/05/our-strategy/'>new strategy</Link> has been described by peers
					as “a valuable and thought-provoking resource” and has given the organisation a clear sense of direction and countless new opportunities to
					increase its impact.
				</p>
				<InlineQuote
					text='Continually in awe of the team at The New Humanitarian. The thoughtful, rigorous and committed way they’ve taken on the really hard (and often painful) work of reimagining what decolonised journalism could look like is a model for how this can be done.”'
					person='Shirish Kulkarni, community organiser, Bureau for Investigative Journalism'
				/>
				<p>
					TNH’s reputation as the only newsroom worldwide that specialises in covering humanitarian crises continues to strengthen. Policymakers and
					practitioners in humanitarian action rely on us to flag emerging crises, highlight those who would otherwise be forgotten, and better
					understand trends in the aid sector.
				</p>
				<p>
					In a 2022 survey, a majority of readers confirmed TNH’s value in reporting on issues and countries not covered by others; in holding the aid
					sector to account through investigative journalism; and in providing analysis and opinions unavailable elsewhere.
				</p>
				<p>
					The New Humanitarian is one of the only organisations to develop a viable model for internationally-focused, public service journalism. With
					strengthened editorial, outreach, operational, and fundraising capacities — and the clear direction of our new strategy — we are
					well-positioned to build on these successes moving forward.
				</p>
			</div>
		),
	},
	{
		title: 'Foreword',
		id: 'foreword',
		backgroundImage: 'foreword',
		className: 'bg-gray-200',
		htmlContent: <ChapterHeader textShadow title={'Foreword'} subtitle={'Daring to be different'} quote={['Daring to be different.']} />,
		fullContent: (
			<div className={'px-5 flow flex flex-col justify-center items-start max-w-6xl mx-auto sm:pr-28'}>
				<InlineTypist
					text={[
						'“The journalism of the future is not the journalism of the past. The practice of journalism has become divorced from the purpose of journalism. What journalism is is becoming more fluid.”  ',
					]}
				/>
				<p>These words, from investigative journalist and community organiser Shirish Kulkarni, are both a statement of fact and a call to action.</p>
				<p>As the world evolves, so too must the practice of journalism.</p>
				<p>
					For one, in a world of artificial intelligence and automation, journalists must re-define their unique added value in the information
					ecosystem.
				</p>
				<p>
					But perhaps more importantly, in a world of growing inequality, increased suffering, and abuse of power, we journalists cannot pretend to be
					divorced from power dynamics. The way we report the news — what we decide to cover and how — either holds power to account, challenges
					power, or helps maintain existing power structures.
				</p>
				<p>
					Over the course of 2022, as part of{' '}
					<Link href='https://interactive.thenewhumanitarian.org/reports/2022/12/05/our-strategy/'>our strategy</Link> to decolonise our journalism,
					we at The New Humanitarian sought to be very conscious of our relationship with dominant narratives, and thus, with power.
				</p>
				<p>
					Instead of reinforcing a narrative that the suffering of millions of people around the world was inevitable, we dared to show that an
					alternative was possible; through our reporting on communities negotiating their own peace deals with jihadists in the Sahel; or in our
					reimagination of global governance on Season 3 of our Rethinking Humanitarianism podcast.
				</p>
				<p>
					Instead of disproportionately focusing — as most international media did — on the conflict in Ukraine, we insisted on telling the stories of
					those millions of others who were also affected by dire humanitarian crises in places like Myanmar, in Ethiopia, in Yemen, in Venezuela.
					These regions were deemed not geopolitically strategic enough to garner attention from the world’s powerful.
				</p>
				<p>
					At a{' '}
					<Link href='https://www.journalismfestival.com/programme/2022/how-to-decolonise-journalism-and-coverage-of-the-global-south'>
						full house
					</Link>{' '}
					session at the International Journalism Festival in Perugia in April 2022, The New Humanitarian explored the racism and white supremacy at
					play in the coverage of the war in Ukraine and the reality that it exposed: international media are writing for powerful audiences in the
					West.
				</p>
				<p>
					Instead of simply exposing wrongdoing amid aid agencies, we sought to understand — in our investigations into sexual exploitation and fraud
					— why they were happening and what systems-level changes would prevent abuse of power in the future.
				</p>
				<p>
					Instead of “he-said, she-said” journalism, we sought to reimagine a role for journalists as mediators between those who have power, and
					those who don’t. Our goal is to facilitate access for the marginalised to decision-makers by actively putting their voices before our
					influential readership, and through closed-door convenings in which we brought them face to face and facilitated conversations among them.
				</p>
				{/* <InlineImage
					fileName={'foreword-inline.jpg'}
					caption={
						'A young boy sits on volcanic rock in Kanyaruchinya, just north of Goma, following the eruption of Nyiragongo. Lava flows razed fields and submerged thousands of homes before stopping just short of Goma’s international airport. (Ley Uwera/TNH)'
					}
				/> */}
				<p>
					As the world grapples with the long-term effects of the invasion of Ukraine, inflation, COVID, climate change, and all the other threats
					facing us, journalism must be better than it has been. The stakes are just too high. In 2022, The New Humanitarian went about trying to
					build a model of what better journalism looks like. The following pages detail how.
				</p>
				<div className={'grid grid-cols-1 gap-x-16 sm:grid-cols-2'}>
					<div className={'flex flex-col'}>
						<div
							className={'w-72 h-96'}
							style={{ background: 'url(/images/reports/annual-report-2022/paula-fray.jpg)', backgroundSize: 'cover' }}
						/>
						<p className="pt-2">
							<strong>Paula Fray</strong>
							<br />
							President
						</p>
					</div>
					<div className={'flex flex-col'}>
						<div className={'w-72 h-96'} style={{ background: 'url(/images/reports/annual-report-2022/heba-aly.jpg)', backgroundSize: 'cover' }} />
						<p>
							<strong>Heba Aly</strong>
							<br />
							CEO
						</p>
					</div>
				</div>
			</div>
		),
	},
	{
		title: 'Strategy',
		id: 'strategy',
		backgroundImage: 'strategy',
		className: 'bg-gray-300',
		htmlContent: (
			<ChapterHeader textShadow title={'Strategy'} subtitle={'Becoming a transformative newsroom'} quote={['Becoming a transformative newsroom ']} />
		),
		fullContent: (
			<div className={'px-5 flow flex flex-col justify-center items-start max-w-6xl mx-auto sm:pr-28'}>
				<InlineTypist text={['Becoming a transformative newsroom.']} />
				<p>
					2022 marked the first year of our new{' '}
					<Link href='https://interactive.thenewhumanitarian.org/reports/2022/12/05/our-strategy/'>five-year strategy</Link> to support our vision of
					becoming a transformative newsroom. Five strategic priorities underpin the strategy:
				</p>
				<ul className={'font-bold pb-5 pt-8'}>
					<li className={'pb-8'}>
						<p>1. Decolonising our journalism</p>
					</li>
					<li className={'pb-8'}>
						<p>2. Centering impact in our work </p>
					</li>
					<li className={'pb-8'}>
						<p>3. Strengthening our newsroom </p>
					</li>
					<li className={'pb-8'}>
						<p>4. Building a more sustainable business model</p>
					</li>
					<li className={'pb-8'}>
						<p>5. Nurturing an organisational culture of excitement, innovation and well-being</p>
					</li>
				</ul>

				<p>
					<strong>We worked more closely with local contributors and voices:</strong> 41 percent of our reporting came from nationals of the countries
					they were reporting on (up from 25 percent in 2021) and 50 percent of the speakers on our podcasts and events were from the Global South.
				</p>
				<p>
					<strong>We distributed our content to local audiences:</strong> In places like Haiti, Lebanon and Somalia, we translated local reporting
					into local languages, and distributed it through local channels to reach local audiences.
				</p>
				<p>
					<strong>We devised and distributed internal guidelines for practising decolonised journalism:</strong> We integrated decolonised journalism
					practices in newsroom planning and through post-mortem documents. For example, we ask reporters to methodically “seek accountability by
					including context and analysis that explores how global and local governments, organisations, companies, and other power-holders may have
					deepened and solidified vulnerability for particular communities.”
				</p>
				<p>
					<strong>We focused on constructive/forward-looking angles within stories:</strong> This approach recognises the authority, agency and
					knowledge of those experiencing crises and depicts them as more than simply victims. Examples include our series on peace-building through
					local dialogue in the Sahel region, and grassroots efforts to maintain education for women in Afghanistan.
				</p>
				<p>
					<strong>We challenged traditional narratives of power and accountability:</strong> We identified those responsible for the suffering of
					communities in countries ranging from Haiti to Argentina, including former colonial powers. Our Rethinking Humanitarianism podcast
					challenged established narratives in aid policy and practice.
				</p>
				<p>
					<strong>We produced more formats that do not require command of English:</strong> These include short-text formats and most importantly
					video, so that language is less of a barrier for non-English speakers to contribute to our journalism.
				</p>
				<p>
					<strong>We trained our editorial team:</strong> Our reporters working in traumatised communities learned to ensure that our sources were not
					re-traumatised during the course of our work.
				</p>
				<p>
					<strong>We shared our approach to decolonising international journalism:</strong> We joined several conferences and took speaking
					engagements to encourage other news publishers to join us on the journey. A private convening we organised with industry peers allowed for
					constructive exchanges between specialist and mainstream media about the future of international journalism.
				</p>
				<InlineImage
					fileName={'strategy-inline.jpeg'}
					caption={
						'“With difficulty, I had managed to find this new job so that I could feed my children, but it seems like there is nothing left for us – poor people – in this country,” said Pushpa, who goes by a single name. Nuwara Eliya/TNH'
					}
				/>
				<h2>Progress on other strategic priorities</h2>
				<h3>Centering impact in our work</h3>
				<p>We took a more active approach to planning and distribution of big projects to achieve maximum impact.</p>
				<p>
					We added private convenings to our toolkit of ways to catalyse change: we hosted critical discussions on decolonising aid and philanthropy
					that provided a safe space for intense but authentic exchanges on this difficult topic, which allowed policy-makers to hear directly from
					affected people about their visions for change.
				</p>
				<p>
					We reached new audiences by ramping up content designed for social media; doubled down on podcast and video production; and translated more
					content into different languages to bring our reporting to more people, including non-English speaking audiences.
				</p>
				<p>We partnered with international and local media to extend our reach.</p>
				<p>We expanded our offline activities to showcase our most powerful stories and themes via events, public speaking and media interviews.</p>
				<p>
					To strengthen our ability to drive impact and audience development further, we created a new post: director of audience and revenue
					development. The role sits at the intersection of the editorial and business sides of our team and develops partnerships to deliver The New
					Humanitarian’s content to our target audiences on different platforms.
				</p>
				<h3>Strengthening the newsroom</h3>
				<p>
					We boosted our editorial team with important new additions; Irwin Loy, who was our Asia editor, became our new policy editor and producer of
					standout coverage on aid policy. We hired a staff editor, Eric Reidy, to lead our coverage of migration and the war in Ukraine; and we
					recruited a new investigative reporter-editor, Jacob Goldberg, to enhance our investigative capacity.
				</p>
				<p>
					We streamlined our risk assessment process and subscribed to additional insurances to manage risks and ensure robust care before, during,
					and after staff deployment to dangerous areas. We also secured psycho-social counseling for all staff and freelance contributors who travel
					to high-risk environments to report for TNH.
				</p>
				<p>We upgraded our website and content management system, which improved performance and streamlined production tasks.</p>
				<h3>Building a more sustainable business model</h3>
				<p>
					Despite the current uncertain economic climate, we grew our funding and made positive steps in diversifying our income. We expanded our
					fundraising team to ensure we can better create and develop relationships with prospective donors, including high-net-worth individuals. We
					also launched an individual giving program.
				</p>
				<p>
					We acquired a paid newsletter product, Dawns Digest, which is now generating modest amounts of revenue; our new director of audience and
					revenue development will develop a broader revenue strategy.
				</p>
				<p>
					We also laid the groundwork for creating a 501c3 charity in the United States, which we aim to launch in late 2023. This will be an
					important plank of our strategy to grow income from US sources over the coming years.
				</p>
				<p>
					We transitioned to the more complex and demanding accounting standards Swiss GAAP FER 21 to provide better accountability and deeper
					transparency to those who fund us.
				</p>
				<p>We continued to grow our reserves with the aim of having three months of operating expenses saved in case of an emergency.</p>
				<h3>Nurturing our culture</h3>
				<p>
					We took significant steps across all aspects of staff recruitment, development, engagement, and welfare. This included making salaries more
					competitive, improving benefits, and introducing greater equity therein.
				</p>
				<p>
					We garnered feedback from staff on how to better support them and encouraged and trained middle managers to be more autonomous and to
					nurture innovation with specific training.
				</p>
				<p>
					We improved and expanded office facilities in Geneva and London to support more flexible working and better collaboration. And we introduced
					specialized counseling for people traveling to high-risk environments to report for TNH.
				</p>
			</div>
		),
	},
	{
		title: 'Our journalism',
		id: 'our-journalism',
		backgroundImage: 'our-journalism',
		className: 'bg-gray-300',
		htmlContent: <ChapterHeader title={'Our journalism'} subtitle={'From the heart of crises'} quote={['From the heart of crises']} />,
		fullContent: (
			<div className={'px-5 flow flex flex-col justify-center items-start max-w-6xl mx-auto sm:pr-28'}>
				<InlineTypist text={['From the heart of crises']} />
				<p>
					Our journalism is dedicated to first-hand, fact-based reporting; committed to enabling local communities to tell the stories they deem most
					important; and upholds the responsibility to speak truth to power by dislodging long-held assumptions and exposing the often hidden roots of
					crises.
				</p>
				<p>In 2022, our journalism connected with audiences via powerful and diverse perspectives and formats:</p>
				<p>
					<strong>First-hand reporting</strong> from{' '}
					<Link href='https://www.thenewhumanitarian.org/news/2022/03/24/funding-shortages-thwart-ethiopia-drought-response-as-crises-multiply'>
						Ethiopia
					</Link>
					,{' '}
					<Link href='https://www.thenewhumanitarian.org/news-feature/2022/07/11/conflict-Myanmar-education-children-junta-military-tatmadaw'>
						Myanmar
					</Link>
					,{' '}
					<Link href='https://www.thenewhumanitarian.org/news-feature/2022/07/13/Venezuela-COVID-fallout-leaves-children-dying-and-the-poor-scavenging-for-food'>
						Venezuela
					</Link>
					,{' '}
					<Link href='https://www.thenewhumanitarian.org/opinion/first-person/2022/1/19/leave-my-family-to-die-photojournalist-Yemen-Marib-interview	'>
						Yemen
					</Link>{' '}
					and many other regions rife with access and security challenges, laying bare the complexities and inconvenient truths that mainstream media
					often miss — and governments often hide.
				</p>
				<p>
					<strong>Convenings</strong> that bring aid professionals, donors and communities in crisis together to{' '}
					<Link href='https://www.thenewhumanitarian.org/the-wrap/2022/08/11/Decolonising-aid-racial-justice-humanitarian-reform'>share views</Link>{' '}
					on everything from what sort of assistance people really want to what a decolonised aid sector might look like.
				</p>
				<p>
					<strong>WhatsApp-based storytelling</strong>, the first of its kind in the world, offering a very{' '}
					<Link href='https://interactive.thenewhumanitarian.org/stories/2022/07/28/whatsapp-lebanon/'>personal look</Link> at what it takes to keep
					going when your country is in freefall.
				</p>
				<p>
					<strong>Reports from communities on the front lines of climate change,</strong> pointing to both{' '}
					<Link href='https://www.thenewhumanitarian.org/photo-feature/2022/08/01/Bangladesh-drought-flood-water-climate-crisis'>
						real-world impacts
					</Link>{' '}
					and real-time fixes.
				</p>
				<p>
					<strong>Investigations that hold power to account,</strong> on issues from{' '}
					<Link href='https://www.thenewhumanitarian.org/investigation/2022/11/14/Haiti-gang-violence-women-gender-based-violence-war-humanitarian-needs'>
						rising violence
					</Link>{' '}
					against women in Haiti to how European courts are{' '}
					<Link href='https://www.thenewhumanitarian.org/authors/ottavia-spaggiari'>wrongfully prosecuting</Link> asylum seekers as smugglers.
				</p>
				<p>
					<strong>Videos that make real the day-to-day indignities of life</strong> for so many and connect the dots between suffering on the ground
					and government aid policies. The videos revealed the impact of the geopolitical{' '}
					<Link href='https://www.thenewhumanitarian.org/film/russia-about-block-key-aid-route-northwest-syria'>tug of war</Link> on aid routes to
					Syria, and how <Link href='https://www.thenewhumanitarian.org/film/politics-famine'>short-term relief efforts</Link> may have helped drive
					seven million Somalis toward hunger.
				</p>
				<p>
					<strong>Playlists spun</strong> to <Link href='https://www.thenewhumanitarian.org/2022/07/28/playlist-whatsapp-lebanon'>stave off</Link>{' '}
					despair around Syria and Lebanon, and to hint at brighter paths ahead.
				</p>
				<h2>From the heart of crises</h2>
				<p className={'text-burgundy'}>
					In 2022, our <strong>first-hand, on-the-ground perspectives from humanitarian emergencies</strong> had a challenging remit….
				</p>
				<p className={'italic'}>
					The social and economic ravages of two pandemic years and Russia’s invasion of Ukraine became painfully apparent across much of the globe in
					2022. Our reporting focused on the{' '}
					<Link href='https://www.thenewhumanitarian.org/news/2022/12/08/Uganda-South-Sudan-refugee-response'>outsized impacts</Link> on communities
					that were struggling long before lockdowns, supply chain glitches, and price rises. We put{' '}
					<Link href='https://www.thenewhumanitarian.org/news-feature/2022/03/28/Mozambique-war-Cabo-Delgado-al-Shabab'>faces</Link> to the
					increasingly dire numbers of people in need and illustrated the real-life consequences of gaps in aid funding: reduced or nonexistent aid
					and government assistance meant that many remained hungry and without access to education, healthcare and even shelter, yet determined to
					find ways forward{' '}
					<Link hregf='https://www.thenewhumanitarian.org/news-feature/2022/03/08/how-yemeni-parents-are-banding-together-keep-their-kids-school'>
						on their own
					</Link>
					.
				</p>
				<p>
					In Caracas, we{' '}
					<Link href='https://www.thenewhumanitarian.org/news-feature/2022/07/13/Venezuela-COVID-fallout-leaves-children-dying-and-the-poor-scavenging-for-food'>
						met families flattened
					</Link>{' '}
					by poverty and a healthcare system that had been in tatters long before COVID-19 emerged. We{' '}
					<Link href='https://www.thenewhumanitarian.org/news-feature/2022/1/17/COVID-education-urgent-humanitarian-issue-school-closures'>
						explored
					</Link>{' '}
					how education has become an urgent humanitarian issue in some regions, a result of pandemic-linked school closures or growing hunger.
				</p>
				<p>
					In India, we <Link href='https://www.thenewhumanitarian.org/news-feature/2022/09/27/india-covid-orphans'>examined</Link> the mental and
					financial toll of the pandemic on the hundreds of thousands of children who had lost parents to Covid.
				</p>
				<p>
					In a series of articles written from largely middle-income countries, we exposed how hunger was{' '}
					<Link href='https://www.thenewhumanitarian.org/analysis/2022/10/19/hunger-food-Egypt-wheat-climate-change-Ukraine'>
						closing in on countries
					</Link>{' '}
					once deemed “safe” from widespread hunger. Reporters for our Hunger Hotspots series spent several months interviewing ordinary consumers,
					aid workers, and food systems experts, revealing how cash-strapped households were cutting back on what and how much they eat, examples of
					rising food insecurity.
				</p>
				<p className={'italif'}>
					On global agendas, the climate justice movement{' '}
					<Link href='https://www.thenewhumanitarian.org/feature/2022/12/20/in-review-climate-change'>gained power</Link> in 2022, in large part the
					product of a more unified message from vulnerable countries and climate advocates. Negotiators emerged from the COP27 UN climate summit with
					an agreement to establish a new stream of funding for so-called “loss and damage” — the{' '}
					<Link href='https://www.thenewhumanitarian.org/news/2022/11/07/COP27-climate-justice-humanitarian-financing-debt-economy-health-food'>
						tally
					</Link>{' '}
					of climate destruction when all else has failed. But for many, this comes much too late. Throughout 2022, our Changing Climate, Changing
					Lives <Link href='https://www.thenewhumanitarian.org/news-feature/2022/09/05/Pakistan-floods-urgent-questions-climate-crisis'>series</Link>{' '}
					featured communities in which loss and damage were already a part of life, in a year that saw wave after wave of emergencies worsened by
					climate change, including severe hunger and famine-like conditions in parts of Somalia and the Horn of Africa; historic floods in Pakistan;
					and extreme heat across the globe.
				</p>
				<p>
					In Iraq, we <Link href='https://www.thenewhumanitarian.org/news/2022/09/06/Iraq-wheat-farmers-climate-change-conflict'>spoke with</Link>{' '}
					farmers and their families who, left without income after droughts, had to abandon farms they had worked for generations. In Bandarban, part
					of the Chittagong Hill Tracts area of southeastern Bangladesh, half of the region’s 1.6 million people lack regular access to water during
					the summer. As visual journalist Zakir Hossain Chowdhury{' '}
					<Link href='https://www.thenewhumanitarian.org/photo-feature/2022/08/01/Bangladesh-drought-flood-water-climate-crisis'>learned</Link>, that
					means fewer days in school as girls spend more time collecting water; less income, as fewer crops grow; and high expenses, as bottled water
					becomes a shopping list staple.
				</p>
				<p className={'text-burgundy'}>
					<strong>The spotlight we shine on forgotten crises and emerging trends</strong> acted as a counterweight to the Western media’s intense
					focus on the war in Ukraine….
				</p>
				<p className={'italic'}>
					A <Link href='https://www.thenewhumanitarian.org/opinion/2022/04/08/event-crisis-coverage-beyond-Ukraine'>myopic</Link> media and donor
					focus on the humanitarian impacts of the Russian invasion of Ukraine diverted attention and funding in 2022 from a host of other settings
					where people were in desperate need. Our reporting spotlighted{' '}
					<Link href='https://www.thenewhumanitarian.org/news/2022/03/07/beyond-ukraine-eight-other-humanitarian-crises-raging-around-globe'>
						those situations
					</Link>
					, from Yemen to Ethiopia, Myanmar to Afghanistan, Syria to Somalia, and many other places.
				</p>
				<p>
					As Ukraine fought against Russian attacks and journalists from the world over rushed to cover the conflict, we defied a media ban in the
					northern Tigray region of Ethiopia to be one of the first news organisations to{' '}
					<Link href='https://www.thenewhumanitarian.org/news-feature/2022/04/26/tigrays-health-system-collapsed'>report</Link> on how, after nearly
					18 months of conflict between the Ethiopian government and Tigray rebels, the region’s health system had “totally collapsed.” It was the
					victim of a months-long{' '}
					<Link href='https://www.thenewhumanitarian.org/analysis/2022/03/29/Security-threats-Tigray-relief-Ethiopia-truce-Afar-Amhara'>
						aid blockade
					</Link>{' '}
					that left six million people with few options for healthcare and{' '}
					<Link href='https://docs.wfp.org/api/documents/WFP-0000136281/download/?_ga=2.85008537.1467131772.1643365972-879553829.1643365972'>
						extreme
					</Link>{' '}
					shortages of food.
				</p>
				<p>
					Although Yemen has long had the dubious distinction of being dubbed the “world’s worst” humanitarian crisis, that doesn’t keep it in the
					media spotlight. We remained committed to reporting on the eight-year conflict, which saw an infusion of hope in 2022 with a ceasefire
					agreement. In a First Person{' '}
					<Link href='https://www.thenewhumanitarian.org/opinion/first-person/2022/04/28/Yemen-Taiz-ceasefire'>essay</Link>, Yemeni aid worker and
					resident Amani Alwarafi shared what that ceasefire and years of war meant to her.
				</p>
				<p className={'italic'}>
					We’ve <Link href='https://www.thenewhumanitarian.org/news/2022/07/29/Haiti-gang-violence-escalates'>consistently reported</Link> on
					different aspects of Haiti’s problems that are more often than not neglected by mainstream and Western media.
				</p>
				<p>
					In an investigation co-published with The Guardian, we{' '}
					<Link href='https://www.thenewhumanitarian.org/investigation/2022/11/14/Haiti-gang-violence-women-gender-based-violence-war-humanitarian-needs'>
						reported
					</Link>{' '}
					from Haiti on the surge in rape against women and rivals as a tactic weaponized by gangs, chronicling a situation that had not previously
					been reported internationally in any detail. The reporting required detailed and flexible planning around the risks of violence and
					kidnapping toward our reporters and their sources; the project was paused several times over security concerns.
				</p>
				<p className={'italic'}>
					Elsewhere, we turned to local journalists and members of local communities to counter prevailing media coverage of the root causes of
					conflict and highlight their stories.
				</p>
				<p>
					In the Democratic Republic of the Congo, for instance, when protests to expel UN peacekeepers broke out, we{' '}
					<Link href='https://www.thenewhumanitarian.org/2022/08/18/why-we-re-protesting-against-un-peacekeepers-dr-congo'>interviewed</Link>{' '}
					protestors like William Mbokani, a 22-year-old Congolese activist. We wanted to understand why they were calling for a withdrawal and who
					they think is best qualified to protect them from armed groups. “Who will come to save the Congo? It is only us young people who can do it,”
					said Mbokani, who was shot in his arm but vowed to keep protesting.v
				</p>
				<p className={'italic'}>
					Political upheavals often cast a crisis into the spotlight. But communities are often soon left to suffer as the media attention and donor
					interest moves elsewhere and the crisis moves from “newsworthy” to chronic — and forgotten by most except those who live in its midst. The
					power shifts in Afghanistan and Myanmar are two such examples, and two crises that The New Humanitarian continued to{' '}
					<Link href='https://www.thenewhumanitarian.org/news-feature/2022/07/11/conflict-Myanmar-education-children-junta-military-tatmadaw'>
						cover
					</Link>{' '}
					despite ongoing access challenges for journalists and aid workers alike, as well as citizens’ increasing reticence to speak openly for fear
					of retribution.
				</p>
				<p>
					As 2022{' '}
					<Link href='https://www.thenewhumanitarian.org/news/2022/2/1/multiple-crises-spiral-Myanmar-one-year-after-coup'>marked one-year</Link> of
					military rule in Myanmar, we pushed to report in ways that kept our journalists and sources safe while breaking through the government’s
					stranglehold on information about new conflict zones, rising hunger, and aid blockades that cut off assistance even as humanitarian needs
					reached{' '}
					<Link href='https://www.thenewhumanitarian.org/news-feature/2022/09/07/Myanmar-India-border-refugee-health-crisis'>record levels</Link>.
				</p>
				<p>
					In Afghanistan, we were one of the few news organisations with an ongoing presence not only in Kabul but elsewhere in the country. We{' '}
					<Link href='https://www.thenewhumanitarian.org/feature/2022/08/30/Afghanistan-1-year-Taliban-anniversary'>chronicled a year</Link> of
					worsening crisis since the Taliban’s return and spoke with young Afghans who were glad that what they saw as the corrupt rule of the
					country’s Western-backed government had ended and were hopeful of a more peaceful era ahead. And we{' '}
					<Link href='https://www.thenewhumanitarian.org/news-feature/2022/11/03/Afghan-girls-education-Taliban'>spoke</Link> to Afghans who, despite
					the risks and the restrictions, were taking matters into their own hands to provide education to women and girls.
				</p>
				<p className={'text-burgundy'}>
					Our mission to <strong>hold the emergency aid sector to account</strong> saw us continuing to challenge, highlight and investigate issues
					ranging from aid effectiveness to funding gaps to sexual abuse….
				</p>
				<p>
					As the aid sector was spinning from the double blow of a teetering global economy and the widespread impacts of the Russian invasion, donors
					were faced with new dilemmas about where to focus their funds and aid organisations were pushed to make tough choices about where they
					operate and how. Our coverage{' '}
					<Link href='https://www.thenewhumanitarian.org/news-feature/2022/07/07/Ukraine-aid-Russia-invasion-funding-donors'>scrutinised</Link>{' '}
					unprecedented funding and resource challenges that were only likely to grow in coming years and how the sector was responding —{' '}
					<Link href='https://www.thenewhumanitarian.org/news/2022/09/13/State-of-the-humanitarian-system-Key-takeaways'>or not</Link> — to these. We
					gave a platform to{' '}
					<Link href='https://www.thenewhumanitarian.org/opinion/2022/2/8/Localisation-lip-service-fixing-aid-colonial-legacy'>constructive</Link>{' '}
					criticism of supposedly positive trends like localisation and we looked for ways forward, from opinion essays on how to begin to fix the
					nonsensical humanitarian funding system to coverage of new and often nontraditional players and{' '}
					<Link href='https://www.thenewhumanitarian.org/podcast/2022/03/31/Fixing-Aid-can-blockchain-help-fix-the-ID-problem-for-a-billion-people'>
						solutions
					</Link>{' '}
					in the aid landscape.
				</p>

				<p>
					We explored how mega-crises such as the Russian invasion of Ukraine can challenge assumptions about how aid works (
					<Link href='https://www.thenewhumanitarian.org/podcast/2022/07/12/Weapons-aid-ukraine'>can weapons be considered aid?</Link>) and expose
					unfulfilled promises (
					<Link href='https://www.thenewhumanitarian.org/analysis/2022/07/25/Ukraine-aid-reform-local-donors-neutrality'>
						vows toward localisation
					</Link>
					). In a{' '}
					<Link href='https://www.thenewhumanitarian.org/opinion/2022/10/17/Somalia-East-Africa-drought-famine-food-humanitarian-aid-financing-reform'>
						powerful opinion
					</Link>{' '}
					piece, the heads of Oxfam GB and Oxfam America argued that the reality of a humanitarian system being financed largely by a few key players
					begging for resources has always been problematic, but today seems nonsensical.
				</p>

				<p>
					On our flagship podcast Rethinking Humanitarianism, we{' '}
					<Link href='https://www.thenewhumanitarian.org/podcast/2022/11/02/Global-Public-Investment-finance'>asked</Link> whether an answer might lie
					in the concept of Global Public Investment (GPI), which boils down to three principles: All contribute, all decide, all benefit. If
					successfully implemented, proponents argue, GPI would be a more democratic financing system for global public goods — better capable of
					meeting the Sustainable Development Goals, tackling the climate crisis, and addressing the pandemics of the future.
				</p>

				<p>
					We also interviewed key humanitarian leaders — from UN Under-Secretary-General for Humanitarian Affairs Martin Griffiths to President of the
					International Committee of the Red Cross, Peter Maurer — to better understand{' '}
					<Link href='https://www.thenewhumanitarian.org/podcast/2022/09/21/Peter-Maurer-ICRC'>their visions</Link> for the sector and hold them
					accountable to their promises. In one of our most listened to podcasts of all time, Griffiths{' '}
					<Link href='https://www.thenewhumanitarian.org/interview/2022/1/28/UN-aid-chief-seeks-focused-inclusive-humanitarian-efforts'>said</Link> he
					hoped he would be the last Brit appointed to the post in an archaic system that reserves top roles at the UN for nationals of specific
					(mostly Western) countries.
				</p>

				<p className={'italic'}>
					Investigations exposed lack of accountability on several fronts and pushed toward justice, uncovering the roots of misconduct, looking at
					ways to address and prevent it, and identifying{' '}
					<Link href='https://www.thenewhumanitarian.org/investigations/2023/03/22/tamil-asylum-seekers-suicide-attempts-diego-garcia-island-rwanda-uk'>
						those
					</Link>{' '}
					who hold the power to prompt change.
				</p>

				<p>
					Nearly a year of{' '}
					<Link href='https://www.thenewhumanitarian.org/2022/09/22/exclusive-alleged-sex-abuse-aid-workers-unchecked-years-un-run-south-sudan-camp'>
						in-depth reporting
					</Link>{' '}
					in South Sudan and a review of documents that had not been made public pointed to years of sex abuse allegations at a UN-run camp that long
					went unchecked. The coverage — a joint project with Al Jazeera — prompted the UN secretary-general to call for an “urgent report” on what
					actions UN officials in South Sudan were taking to “ensure accountability.”
				</p>

				<p>
					As one of the reporters on the story, Sam Mednick, explained: “What we found was a system-wide failure to protect women and girls from
					sexual exploitation at the hands of aid workers. … Aid organisations had known about this for years and there was little done to address the
					problem.”
				</p>

				<p>
					An accompanying analysis looked at why{' '}
					<Link href='https://www.thenewhumanitarian.org/feature/2021/2/11/25-years-of-sexual-exploitation-and-abuse'>
						sex abuse and exploitation
					</Link>{' '}
					has long dogged the UN and the aid sector.{' '}
				</p>

				<p>
					From Europe, we exposed a trend that has been building since the 2015 migration crisis: European countries are increasingly{' '}
					<Link href='https://www.thenewhumanitarian.org/investigation/2022/09/01/European-courts-prosecution-asylum-seekers'>
						prosecuting asylum seekers
					</Link>{' '}
					and migrants using laws intended to combat human smuggling. The same laws have also been used to crack down on civil society organisations
					and activists providing humanitarian support to people migrating. But while those cases — mainly involving{' '}
					<Link href='https://www.thenewhumanitarian.org/news-feature/2019/06/20/european-activists-fight-criminalisation-aid-migrants-refugees'>
						European citizens
					</Link>{' '}
					— often garner media attention, cases involving asylum seekers and migrants are frequently overlooked.
				</p>

				<p>
					Exposing the problem is not the end of the story in our investigations. Working with researcher Kristof Titeca, we{' '}
					<Link href='https://www.thenewhumanitarian.org/investigation/2022/12/07/Uganda-UNHCR-refugee-fraud-corruption'>revealed</Link> that neither
					the Ugandan government nor the UN had brought to justice key figures involved in an aid fraud scandal, which The New Humanitarian exposed in
					2018. In looking at who paid the price (and who didn’t) for the scandal, we found that almost five years on, many of those most involved
					appear to have avoided legal or professional repercussions.
				</p>

				<InlineImage fileName={'datelines-2022.jpg'} />
			</div>
		),
	},
	{
		title: 'Our impact',
		id: 'our-impact',
		backgroundImage: 'our-impact',
		className: 'bg-gray-200',
		htmlContent: (
			<ChapterHeader
				textShadow
				title={'Our impact'}
				subtitle={'Inform, empower, stimulate'}
				quote={['How our journalism creates real impact.', 'Inform, empower, stimulate']}
			/>
		),
		fullContent: (
			<div className={'px-5 flow flex flex-col justify-center items-start max-w-6xl mx-auto sm:pr-28'}>
				<InlineTypist text={['How our journalism creates real impact.']} />
				<p>
					In 2022, our work had a significant impact in multiple ways — prompting UN investigations, empowering local communities, and stimulating
					critical debate.
				</p>
				<p>
					We co-produced an{' '}
					<Link href='https://www.thenewhumanitarian.org/2022/09/22/exclusive-alleged-sex-abuse-aid-workers-unchecked-years-un-run-south-sudan-camp'>
						investigation
					</Link>{' '}
					with Al Jazeera into allegations of sexual abuse by aid workers in South Sudan, exposing how such abuse had been unchecked since 2015,
					despite the intervention of a UN task force. Our reporting prompted a UN investigation, pledges from the South Sudanese government to take
					action, and condemnation from NGOs.
				</p>
				<p>
					Our <Link href='https://www.thenewhumanitarian.org/analysis/2022/04/04/haiti-wide-gap-between-aid-promise-and-reality'>story</Link> on the
					failure of aid to deliver benefits to ordinary Haitians (a joint survey with Ground Truth Solutions that was also published in Creole) was
					used by the UN Office for Coordination of Humanitarian Affairs (UNOCHA) to brief management as they considered a new initiative on
					accountability. Our opinion piece on how{' '}
					<Link href='https://www.thenewhumanitarian.org/opinion/2022/04/11/international-aid-Ukraine'>international aid misread Ukraine</Link>{' '}
					prompted a staff discussion at a major international NGO on what lessons they can learn from this. An evaluation of their Ukraine response
					was initiated, with the TNH article cited as recommended reading.
				</p>
				<p>
					Our series on{' '}
					<Link href='https://www.thenewhumanitarian.org/2022/05/16/kidnapped-teacher-fed-farmer-and-push-dialogue-mali-s-militants'>
						local dialogue with militants
					</Link>{' '}
					in Burkina Faso and Mali, published in French and English, was described as “invaluable” and made waves amongst Sahel experts. The renowned
					Sahel/Sahara academic Yvan Guichaoua tweeted: “The whole series on dialogue with jihadists in the #Sahel by @newhumanitarian forms an
					exceptionally rare and precious body of evidence on civilians/militants relationships in the region. Journalism at its best.”
				</p>
				<InlineImage
					fileName={'dialogue.jpeg'}
					caption={
						'A depiction of a dialogue between jihadists and community leaders in central Mali. Dozens of such talks have been held in the region since mid-2020. Dramane Diarra/TNH'
					}
				/>
				<p>
					Our{' '}
					<Link href='https://www.thenewhumanitarian.org/the-wrap/2022/08/11/Decolonising-aid-racial-justice-humanitarian-reform'>
						private convening
					</Link>{' '}
					on decolonising aid brought together more than 50 government donors, UN agencies, racial justice activists and local humanitarians. Many
					participants called it a watershed moment and a rare opportunity to directly speak truth to power. The majority of participants told us the
					event deepened understanding and prompted internal debate in government aid departments and philanthropic foundations. One example is to
					examine how to overcome challenges to fund more programs locally.
				</p>
				<p>
					Our 2022 audience survey quantified the impact of our work: 61 percent of respondents said TNH stimulated further research and/or advocacy;
					31 percent said TNH informed organisational and operational priorities, including the deployment of staff or resources; and 26 percent said
					TNH led them or their organisation to push for internal or external policy change.
				</p>
				<InlineQuote
					person={'audience survey respondent'}
					text={
						'“The debate on decolonisation and localisation has enabled me to push the agenda further with my board, who are the usual humanitarian actors who say the right things but are mostly resistant to change.”'
					}
				/>
			</div>
		),
	},
	{
		title: 'Connecting with our audience',
		id: 'connecting-with-our-audience',
		backgroundImage: 'connecting-with-our-audience',
		className: 'bg-gray-300',
		htmlContent: (
			<ChapterHeader textShadow title={'Connecting with our audience'} subtitle={'Building better journalism'} quote={['Building better journalism']} />
		),
		fullContent: (
			<>
				<div className={'px-5 flow flex flex-col justify-center items-start max-w-6xl mx-auto sm:pr-28'}>
					<InlineTypist text={['Building better journalism']} />
					<p>
						In 2021, our average monthly web users settled after the dramatic peaks in 2020 attributed to our coverage of the COVID-19 pandemic. On
						average, we had 278,987 monthly visitors to our website, with a high of 377,575 in April, after our investigation into new Oxfam
						misconduct allegations in Iraq and “rotten” work culture in Congo.
					</p>
					<p>
						In 2022, we reached audiences through an increasing range of platforms. On average, 210,000 people visited our website every month, for
						a total of 2,410,000 page views over the course of the year. Some 67,000 people signed up to our free daily and weekly newsletters; and
						six major institutions subscribed to our new paid newsletter offering. A new strategy to produce bespoke products for social media led
						to a 21 percent increase in followers on social media platforms, for a total of more than 227,000.{' '}
					</p>
					<p>
						Results from our 2022 reader survey underlined the influential nature of our audience in the humanitarian sector: 52 percent of
						respondents were senior professionals or executive management/C-suite level, and 57 percent of respondents said their work has a major
						or moderate impact on humanitarian action and policy.
					</p>
					<p>
						Audience consumption and interaction with our journalism reflects a growing preference for multimedia, with stellar growth in our
						podcast audience (downloads were up 45 percent year-on-year to 145,853) and video views (up by 19 percent to 615,000 views).
					</p>
					<p>
						Like many other news sites, TNH’s website traffic saw a decline due to various factors including changes to Google’s search algorithm,
						but engagement with our stories (measured by average reading time) remained well above the industry average.
					</p>
					<p>
						TNH’s presence on the global stage increasingly underlined the multi-dimensionality of our journalism and our audience. We are sought
						out for our agenda-setting voices on seismic shifts in the aid landscape, as well as important new trends in journalism, such as the
						inclusion of local voices in storytelling.
					</p>
					<p>
						In 2022, this was borne out by our involvement in more than 18 speaking engagements targeted at the media industry (International
						Journalism Festival in Perugia), our core humanitarian audience (European Humanitarian Forum in Brussels), and wider audiences (World
						Economic Forum in Davos).
					</p>
					<MarginBreakContainer>
						<div className={'gap-x-10 gap-y-16 grid grid-cols-1 sm:grid-cols-3 justify-center items-start sm:mx-0 md:-mx-10 lg:-mx-56 xl:-mx-80'}>
							<div className={'flex items-center justify-center flex-col text-center'}>
								<div className={'text-burgundy w-32'}>
									<Icons.Eyes />
								</div>
								<h3 className={'text-3xl sm:text-5xl'} style={{ marginTop: '2rem' }}>
									2,410,000 total visitors in 2022
								</h3>
							</div>

							<div className={'flex items-center justify-center flex-col text-center'}>
								<div className={'text-burgundy w-32'}>
									<Icons.Eyes />
								</div>
								<h3 className={'text-3xl sm:text-5xl'} style={{ marginTop: '2rem' }}>
									210,134 average monthly users
								</h3>
							</div>

							<div className={'flex items-center justify-center flex-col text-center'}>
								<div className={'text-burgundy w-32'}>
									<Icons.StopWatch />
								</div>
								<h3 className={'text-3xl sm:text-5xl'} style={{ marginTop: '2rem' }}>
									3.34 min. average time spent reading an article
								</h3>
								<p className={'m-0'}>(as compared to industry standard of 1.82 min. for top 50 US newspapers)</p>
							</div>

							<div className={'flex items-center justify-center flex-col text-center'}>
								<div className={'text-burgundy w-32'}>
									<Icons.Email />
								</div>
								<h3 className={'text-3xl sm:text-5xl'} style={{ marginTop: '2rem' }}>
									67,586 newsletter subscribers
								</h3>
							</div>

							<div className={'flex items-center justify-center flex-col text-center'}>
								<div className={'text-burgundy w-32'}>
									<Icons.SocialMedia />
								</div>
								<h3 className={'text-3xl sm:text-5xl'} style={{ marginTop: '2rem' }}>
									227,301 social media followers
								</h3>
							</div>

							<div className={'flex items-center justify-center flex-col text-center'}>
								<div className={'text-burgundy w-32'}>
									<Icons.Group />
								</div>
								<h3 className={'text-3xl sm:text-5xl'} style={{ marginTop: '2rem' }}>
									500 members
								</h3>
							</div>

							<div className={'flex items-center justify-center flex-col text-center'}>
								<div className={'text-burgundy w-32'}>
									<Icons.Newspaper />
								</div>
								<h3 className={'text-3xl sm:text-5xl'} style={{ marginTop: '2rem' }}>
									Republication by more than 20 publishers
								</h3>
							</div>

							<div className={'flex items-center justify-center flex-col text-center'}>
								<div className={'text-burgundy w-32'}>
									<Icons.Podcast />
								</div>
								<h3 className={'text-3xl sm:text-5xl'} style={{ marginTop: '2rem' }}>
									145,883 podcast downloads
								</h3>
							</div>
						</div>
					</MarginBreakContainer>
					<h2 className={'text-center w-full'}>In their words</h2>
					<InlineQuote
						person={
							'Margareta Wahlstrom, President Swedish Red Cross and former head of the United Nations Office for Disaster Risk Reduction (UNDRR)'
						}
						text={
							'“Thank you for the important work the New Humanitarian is doing to be true to what humanitarians ought to be and to always challenge established behaviours and ‘truths.’ You have the professionalism and determination to be uncomfortable in your reporting. Keep going!”'
						}
					/>
					<InlineQuote
						person={'Aid worker'}
						text={
							'“We need you. When new people are looking to come into the sector, and ask me where to turn, I tell them to read The New Humanitarian. That’s where the truth is.”'
						}
					/>
					<InlineQuote
						person={'Government representative'}
						text={
							'“Congratulations on [hosting this convening on decolonising aid]. It was probably the most irritating, frustrating, stimulating, fascinating conversation that I’ve had for a few years. It was just extraordinary. I felt very challenged.  You’ve been brave to do this. It’s brilliant what you’ve done.”'
						}
					/>
					<InlineQuote
						person={'Barney Cullum, global media and communications manager, Transparency International UK'}
						text={
							'“TNH’s series from Mali and Burkina Faso throughout this year has been incredible work, really excellent and important reporting.”'
						}
					/>
					<InlineQuote
						person={'Alex Gray, Center for Disaster Philanthropy (17th August) '}
						text={
							'“You all do such important work and it influences my own work significantly. We all love The New Humanitarian at CDP. Faithful and long term reader/fan here.”'
						}
					/>
					<InlineQuote
						person={'Holly Miller, Action Aid '}
						text={
							'“Your article on the network gave us visibility that allowed us to raise millions of dollars for women that will never be able to be present in fora like these. So I just wanted to thank you.”'
						}
					/>
					<h2>On the global stage</h2>
					<p>
						TNH’s presence on the global stage increasingly underlines the multi-dimensionality of our journalism. We are sought out for our expert
						and agenda-setting voices on issues in aid, such as the decolonisation approach, and on important new trends in journalism like the
						inclusion of local voices in storytelling.{' '}
					</p>
					<p>
						In 2022, this was borne out by our involvement in different fora via more than 18 speaking engagements targeted at the media industry
						(e.g. International Journalism Festival in Perugia), our core humanitarian audience (e.g. European Humanitarian Forum in Brussels), and
						wider audiences (e.g. World Economic Forum in Davos).{' '}
					</p>
					<p>
						The media sector took note of our journalism innovations, which were referenced in web design-focussed publisher It’s Nice That, the
						Global Investigative Journalism Network and Journalism.co.uk, signalling increasing respect for our work among our peers.
					</p>
					<p>
						We hosted four public events and three closed door convenings, including a discussion on decolonising aid that was attended by more than
						50 senior policy-makers, local humanitarians and racial justice activists, which allowed us to have direct impact in new ways.{' '}
					</p>
					<p>
						To support our drive to broaden our reach, we trained our editorial team on public speaking in media interviews so they can more
						effectively bring our stories to wider audiences through third-party broadcasters.{' '}
					</p>
					{/* <p>
						Our <strong>Executive Editor Josephine Schmidt</strong> gave a keynote on storytelling for good at the Berlin Human Rights Film
						Festival:
					</p>
					<InlineYoutubeVideo
						src={'https://www.youtube.com/embed/V6zZydNV7sU'}
						caption={
							<span>
								<a target={'_blank'} rel={'noopener noreferrer'} className={'font-bold'} href={'https://www.youtube.com/embed/V6zZydNV7sU'}>
									Click here
								</a>{' '}
								to watch the full video on Youtube.
							</span>
						}
					/> */}
					<h2>Highlights of appearances during the year include:</h2>
					<p>
						<strong>CEO Heba Aly</strong> moderated a number of{' '}
						<Link href='https://www.weforum.org/event_player/a0P68000000ZvnvEAC/sessions/unlocking-employment-for-refugees'>prominent panels</Link>{' '}
						at the World Economic Forum, including one on refugee labour markets. Aly also moderated high-profile sessions at the European
						Humanitarian Forum in Brussels, including the opening and closing panels, and sessions on Ukraine and the Nexus.
					</p>
					<p>
						At the World Bank’s Fragility Forum, The New Humanitarian collaborated with the Stanley Center for Peace and Security to produce a
						series of <Link href='https://www.youtube.com/watch?v=G4ybd_25Et8'>podcasts</Link> featuring TNH journalists speaking about their
						reporting on peacebuilding issues around the world.
					</p>
					<InlineYoutubeVideo
						src={'https://www.youtube.com/embed/G4ybd_25Et8'}
						caption={
							<span>
								<a target={'_blank'} rel={'noopener noreferrer'} className={'font-bold'} href={'https://www.youtube.com/embed/G4ybd_25Et8'}>
									Click here
								</a>{' '}
								to watch the full video on Youtube.
							</span>
						}
					/>
					<h2>Awards</h2>
					<p>
						Our reporting on women and girls continues to build momentum. Two features were longlisted in the Womens Solutions Reporting category at
						the One World Media Awards; the multimedia{' '}
						<Link href='https://interactive.thenewhumanitarian.org/stories/2021/12/21/bangladesh-rohinyga-camp-women-illustration/'>story</Link> of
						two young women Romida and Hafsa, pushing for change in a Rohingya refugee camp and Zainab Chamoun, a reporter based in Beirut, was
						recognized for her coverage of Lebanese women challenging both cultural norms and promoting eco-friendly approaches to period products
						in the story “
						<Link href='https://www.thenewhumanitarian.org/news-feature/2021/9/9/lebanese-women-fight-period-poverty-with-eco-friendly-alternatives?utm_source=twitter&utm_medium=social&utm_campaign=social'>
							Lebanese Women Fight Period Poverty with eco-friendly alternatives.
						</Link>
						”
					</p>
					<p>TNH’s illustrated interactive timeline, WhatsApp, Lebanon? won accolades.</p>
					<p>
						The feature was runner up in the ‘best innovation project on a website’ category in the Eppy Awards honouring the best in digital news
						publishing; and was also long-listed in the Information is Beautiful Awards.
					</p>
					<InlineImage
						fileName={'WhatsAppers.png'}
						caption={
							'Illustration from WhatsApp Lebanon, a different kind of timeline, a different kind of story. Illustration by Rafik El Hariri.'
						}
					/>
					<p>
						Finally, TNH’s short <Link href='https://www.youtube.com/watch?v=RtKWt7QHMm8'>video reportage</Link> of US President Donald Trump using
						COVID-19 to close off the US-Mexico border to gay and trans asylum seekers was officially selected for the Tulum World Environment Film
						Festival.
					</p>
				</div>
			</>
		),
	},
	{
		title: 'Funding and governance',
		id: 'funding-and-governance',
		backgroundImage: 'funding-and-governance',
		className: 'bg-gray-200',
		htmlContent: (
			<ChapterHeader
				textShadow
				title={'Funding and governance'}
				subtitle={'The foundations that position us for success'}
				quote={['The foundations that position us for success']}
			/>
		),
		fullContent: (
			<div className={'px-5 flow flex flex-col justify-center items-start max-w-6xl mx-auto sm:pr-28'}>
				<InlineTypist text={['The foundations that position us for success']} />
				<p>
					Since 2021, our financial statements have been produced according to the Swiss GAAP FER 21 accounting standards and in 2022, our financial
					statements were subject to a full and independent audit by Berney Associés of Geneva in accordance with NA-CH 700 standards. Their audit
					report and our 2022 financial statements can be found in Annex 1.
				</p>
				<p>
					Since 2021, our financial statements have been produced according to the Swiss GAAP FER 21 accounting standards and in 2022, our financial
					statements were subject to a full and independent audit by Berney Associés of Geneva in accordance with NA-CH 700 standards. Their audit
					report and our 2022 financial statements can be found in Annex 1.
				</p>
				<p>
					Our membership income grew to 51,639 CHF in 2022; and paid services, including individual donations and speaking fees generated CHF 5,559.
				</p>
				<p>Our expenditure in 2022 totalled 3,003,031 CHF.</p>
				<p>
					We ended the year with a net surplus of 99,586 CHF, through membership, revenue-generating activities and portions of grants that donors
					specifically allow us to use to build reserves. After more than six years of operations as an independent entity, we had accumulated a total
					capital of 494,024 CHF by the end of 2022.
				</p>
				<InlineImage
					fileName={'syria-cholera.jpeg'}
					caption={
						'Millions of people in northwest Syria live in cramped conditions with poor sanitation, creating fertile conditions for the potential spread of cholera. Muhammad Al Hosse/TNH'
					}
				/>
				<h2>Staffing</h2>
				<p>With a reinforced operations team in 2022, we embarked on a number of projects aimed at professionalising how we work.</p>
				<ul className={'font-bold pb-5 pt-8'}>
					<li className={'pb-8'}>
						<p>
							Our COO and web developer undertook a risk assessment of our overall IT/web infrastructure and services and identified a number of
							actions we will undertake in 2023 to improve security and performance.
						</p>
					</li>
					<li className={'pb-8'}>
						<p>
							We established a new and more automated workflow to ensure separation of duties in approval of expenses, decentralised
							decision-making and clarified roles and responsibilities. We reinforced compliance with sanctions and anti-terrorism legislation.
						</p>
					</li>
					<li className={'pb-8'}>
						<p>We launched a new Per Diem Policy to reduce the administrative burden and optimise staff time.</p>
					</li>
					<li className={'pb-8'}>
						<p>
							We strengthened our approach to duty of care for the journalists we send to high-risk locations, including beefing up our risk
							assessment process, rolling out our partnership with International SoS and introducing other insurances to support our capabilities
							to manage risk and critical incidents for staff and freelancers alike.
						</p>
					</li>
					<li className={'pb-8'}>
						<p>
							We implemented a review of a number of aspects of our staff policies including salaries, cost of living indices and re-examined our
							overall approach to recruitment. As a result, we made salary and benefit changes and also took action to ensure we are fully
							compliant in the different jurisdictions in which we employ people. These changes and our introduction of psycho-social counselling
							(via Konterra) for all staff and freelance contributors who travel to high-risk environments are particularly important as we build
							our increasingly dispersed team.
						</p>
					</li>
				</ul>
				<p>
					In 2022 we strengthened our team to support our ambitious new 5 year strategy and the further growth and development it entails. This meant
					key hires across finance, editorial, audience development and recruitment and by the end of the year our team had grown to 23 employees in
					11 countries around the world.
				</p>
				<p>We also welcomed the new Executive Director of Human Rights Watch, Tirana Hassan, to our Board of Directors.</p>
			</div>
		),
	},
]

export default CHAPTERS
